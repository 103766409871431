
export const sortBy = (field, reverse, primer) => {

  let key = primer ? (x) => primer(x[field]) : (x) => x[field]

  reverse = !reverse ? 1 : -1

  return (a, b) => {
      return a = key(a), b = key(b), reverse * ((a > b) - (b > a))
    } 
}

export const debounce = (fn, time) => {
  let timeout;

  return function() {
    const functionCall = () => fn.apply(this, arguments);
    
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  }
}