export const sortOptions = {
  isDescending: true,
  values: {
    INTEREST: 'interest',
    NAME: 'name',
    RATING: 'rating',
    TOTAL_COST: 'totalCost'
  }
}

export const loanOptions = {
  fi: {
    defaultLoanAmount: 500,
    defaultValue: 500,
    defaultLoanPeriod: 3,
    availableFilters: {
      83: 'Autolaina',
      91: 'Asuntolaina',
      129: 'Factoring',
      101: 'Joustoluotto',
      75: 'Kulutusluotto',
      106: 'Lainojen Yhdistäminen',
      89: 'Luottokortti',
      73: 'P2P',
      37: 'Pikalaina',
      104: 'Reissuluotto',
      123: 'Säästötili',
      127: 'Suljettu',
      81: 'Tililuotto',
      99: 'Vakuutus',
      42: 'Yrityslaina'
    },
    amounts: [
      50,
      100,
      200,
      300,
      400,
      500,
      600,
      700,
      800,
      900,
      1000,
      1100,
      1200,
      1300,
      1400,
      1500,
      1600,
      1700,
      1800,
      1900,
      2000,
      2500,
      3000,
      3500,
      4000,
      4500,
      5000,
      6000,
      7000,
      8000,
      9000,
      10000,
      15000,
      20000,
      25000,
      35000,
      40000,
      45000,
      50000,
      75000,
      100000,
      200000,
      250000,
      300000,
      350000,
      400000,
      500000,
      600000,
      700000,
      800000,
      900000,
      1000000
    ],
    loanPeriods: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      18,
      24,
      36,
      48,
      60,
      72,
      84,
      96,
      108,
      120,
      132,
      144,
      156,
      168,
      180
    ],
    loanAmountStepStart: 5
  },
  sv: {
    defaultLoanAmount: 5000,
    defaultValue: 5000,
    defaultLoanPeriod: 3,
    availableFilters: {
      85: 'Billån',
      93: 'Bostadslån',
      114: 'Factoring',
      108: 'Flexlån',
      69: 'Företagslån',
      116: 'Låneförmedlare',
      95: 'Försäkring',
      79: 'Kontokredit',
      71: 'P2P',
      77: 'Privatlån',
      110: 'Samlingslån',
      112: 'Semesterlån',
      67: 'Snabblån',
      121: 'Sparkonto',
      125: 'Avstängd'
    },
    amounts: [
      500,
      1000,
      1500,
      2000,
      3000,
      4000,
      5000,
      6000,
      7000,
      8000,
      9000,
      10000,
      11000,
      12000,
      13000,
      14000,
      15000,
      16000,
      17000,
      18000,
      19000,
      20000,
      25000,
      35000,
      40000,
      45000,
      50000,
      100000,
      150000,
      200000,
      250000,
      300000,
      350000,
      400000,
      450000,
      500000,
      550000,
      600000
    ],
    loanPeriods: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      18,
      24,
      36,
      48,
      60,
      72,
      84,
      96,
      108,
      120,
      132,
      144,
      156,
      168,
      180
    ],
    loanAmountStepStart: 0
  }
}
