import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Slider from 'rc-slider/lib/Slider'
// We can just import Slider or Range to reduce bundle size
// import Slider from 'rc-slider/lib/Slider';
// import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css'
import './scss/index.scss'
import LoanListItem from './LoanListItem'
import translate from '../../i18n/loanList'
import { sortBy } from '../../utils/list-utils'
import { loanOptions } from './config'

const array_any = (needles, haystack) =>
  needles.some(needle => haystack.includes(needle))

const mapBusinessLoans = loans => {
  // /currency_sites_components/packages/loan_list/src/containers/LoanList/config/index.js
  //42:Yrityslaina 69:Företagslån
  const businessLoanIds = ['Yrityslaina', 'Företagslån']
  return loans.map(loan => {
    loan.isBusinessLoan = array_any(loan.lender_type.split('|'), businessLoanIds)
    return loan
  })
}

function LoanList(props) {

  const lenders = mapBusinessLoans(props.items)


  const [currentSliderStep, setCurrentSliderStep] = useState(0)
  const currentLanguage = 'sv'
  const [loanAmount, setLoanAmount] = useState(
    props.loanAmount || loanOptions[currentLanguage].defaultLoanAmount
  )

  const [searchFilters, setSearchFilters] = useState({
    accepts_non_payment_record: props.accepts_non_payment_record || false,
    has_interest_free_loans: props.has_interest_free_loans || false,
    has_weekend_payouts: props.has_weekend_payouts || false,
    does_not_use_credit_check: props.does_not_use_credit_check || false
  })

  const [showOnlyBusinessLoans, setShowOnlyBusinessLoans] = useState(
    props.showOnlyBusinessLoans || false
  )

  const exclusiveTaxonomies = props.exclusiveTaxonomies
    ? props.exclusiveTaxonomies.split(',').map(n => parseInt(n, 10))
    : []

  const monthsOrYears = months => {
    return months >= 12
      ? months / 12 + ' ' + translate('year', currentLanguage)
      : months + ' ' + translate('month', currentLanguage)
  }

  useEffect(() => {
    const currentStep = loanOptions[currentLanguage].amounts.findIndex(
      val => val === loanAmount
    )
    setCurrentSliderStep(currentStep)
  }, [loanAmount])

  useEffect(() => {
    if (showOnlyBusinessLoans) {
      setLoanAmount(currentLanguage === 'sv' ? 50000 : 5000)
    }
  }, [showOnlyBusinessLoans])

  const onLoanAmountChange = value => {
    setLoanAmount(loanOptions[currentLanguage].amounts[value])
  }

  const onBusinessLoanChange = () => {
    setShowOnlyBusinessLoans(!showOnlyBusinessLoans)
  }

  const filterBusinessLoans = loan => {
    return loan.isBusinessLoan === showOnlyBusinessLoans
  }

  const filterLoans = loan => {
    // We filter out business loans unless check box is ticked then we filter out all private loans instead
    // If any taxonomies were set in WP admin to make the list more exclusive
    // this is where we filter out anything that is not tagged as one of the
    // exclusive taxonomies
    if (
      exclusiveTaxonomies.length > 0 &&
      !array_any(loan.lender_type, exclusiveTaxonomies)
    ) {
      return false
    }

    // Loan amount slider filtering
    if (loan.min_loan_amount > loanAmount) return false
    if (loan.max_loan_amount < loanAmount) return false

    // Checkbox Filtering
    return Object.keys(searchFilters)
      .filter(key => !!searchFilters[key])
      .every(key => searchFilters[key] == loan[key])
  }

  const updateSearchFilter = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setSearchFilters({
      ...searchFilters,
      [name]: value
    })
  }

  return (
    <div className="aff-list-container">
      <div className="search-container">
        <div className="search-filters">
          <div className="search-filters__box">
            <div className="search-filters__split-container">
              <span className="search-filters__label fl">
                {translate('loan_amount_title', currentLanguage)}:
              </span>
              <span className="search-filters__label fr">
                {loanAmount} {translate('currency_unit', currentLanguage)}
              </span>
            </div>
            <Slider
              step={1}
              min={0}
              max={loanOptions[currentLanguage].amounts.length - 1}
              onChange={onLoanAmountChange}
              onAfterChange={onLoanAmountChange}
              defaultValue={loanOptions[currentLanguage].loan}
              value={currentSliderStep}
            />
          </div>
          <div className="search-filters__box">
            <div className="search-filters__box--item">
              <label className="control control-checkbox">
                <span>
                  {translate('show_only_business_loans', currentLanguage)}
                </span>
                <input
                  type="checkbox"
                  name="show_only_business_loans"
                  value={showOnlyBusinessLoans}
                  defaultChecked={showOnlyBusinessLoans}
                  onChange={onBusinessLoanChange}
                />
                <div className="control_indicator" />
              </label>
            </div>
            <div className="search-filters__box--item">
              <label className="control control-checkbox">
                <span>
                  {translate('accepts_non_payment_record', currentLanguage)}
                </span>
                <input
                  type="checkbox"
                  name="accepts_non_payment_record"
                  value={searchFilters.accepts_non_payment_record}
                  onChange={updateSearchFilter}
                />
                <div className="control_indicator" />
              </label>
              <label className="control control-checkbox">
                <span>{translate('interest_free_loan', currentLanguage)}</span>
                <input
                  type="checkbox"
                  name="has_interest_free_loans"
                  value={searchFilters.has_interest_free_loans}
                  onChange={updateSearchFilter}
                />
                <div className="control_indicator" />
              </label>

              {currentLanguage === 'sv' && (
                <label className="control control-checkbox">
                  <span>
                    {translate('loan_without_credit_check', currentLanguage)}
                  </span>
                  <input
                    type="checkbox"
                    name="does_not_use_credit_check"
                    onChange={updateSearchFilter}
                    checked={searchFilters.does_not_use_credit_check === true}
                  />
                  <div className="control_indicator" />
                </label>
              )}

              <label className="control control-checkbox">
                <span>{translate('weekend_payout', currentLanguage)}</span>
                <input
                  type="checkbox"
                  name="has_weekend_payouts"
                  value={searchFilters.has_weekend_payouts}
                  onChange={updateSearchFilter}
                />
                <div className="control_indicator" />
              </label>
            </div>
          </div>
        </div>
        <ul className={'aff-list'}>
          {lenders
            .filter(lender => lender.is_paused !== '1')
            .filter(filterBusinessLoans)
            .filter(filterLoans)
            // .filter(limitBy)
            .sort(sortBy('is_featured', true, parseFloat))
            .map((loan, i) => (
              <LoanListItem
                currentLanguage={currentLanguage}
                key={i}
                loan={loan}
                loanAmount={loanAmount}
              />
            ))}
        </ul>
      </div>
    </div>
  )
}

LoanList.propTypes = {
  language: PropTypes.string,
  host: PropTypes.string,
  accepts_non_payment_record: PropTypes.bool,
  has_interest_free_loans: PropTypes.bool,
  has_weekend_payouts: PropTypes.bool,
  does_not_use_credit_check: PropTypes.bool,
  showOnlyBusinessLoans: PropTypes.bool,
  exclusiveTaxonomies: PropTypes.string,
  loanAmount: PropTypes.number
}

export default LoanList
