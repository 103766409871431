import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'gatsby'
import './scss/slider.scss'

export default function Slider({ links }) {
  return (
    <>
      <Menu right>
        <ul className="slider_links">
          {links.map((link, i) => (
            <li className={`link_item ${link.is_title ? 'title-link' : ''}`} key={i}>
              <Link className="menu-item" to={link.path}>
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      </Menu>
    </>
  )
}
