import React from 'react';
import translate from '../../i18n/loanList';
import PropTypes from 'prop-types';
import AffiliateLinkButton from '../AffiliateLinkButton'

LoanListItem.propTypes = {
  loan: PropTypes.object,
  loanAmount: PropTypes.number,
  currentLanguage: PropTypes.string,
};

export default function LoanListItem({
  loan,
  loanAmount,
  currentLanguage,
}) {
  return (
    <li className={'aff-list-item'} key={loan.id}>
      {loan.is_featured === '1' && (
        <div className="ribbon">
          <span>{translate('featured_ribbon', currentLanguage)}</span>
        </div>
      )}
      <div className="aff-logo">
        <img alt={loan.title} src={loan.image} />
      </div>

      <table className="aff-list__table">
        <tbody>
          <tr>
            <td>{`❗ ${translate('age_limit', currentLanguage)} `}</td>
            <td>{`${loan.age_limit} ${translate('year', currentLanguage)}`}</td>
          </tr>
          <tr>
            <td>{`🗓️ ${translate('loan_period', currentLanguage)}`}</td>
            <td>{` ${loan.min_loan_period} -
          ${loan.max_loan_period} ${translate('month', currentLanguage)}`}</td>
          </tr>
          <tr>
            <td>{`💵 ${translate('min_loan_amount', currentLanguage)}`}</td>
            <td>{`${loan.min_loan_amount} ${translate(
              'currency_unit',
              currentLanguage
            )}`}</td>
          </tr>
          <tr>
            <td>{`💰 ${translate('max_loan_amount', currentLanguage)} `}</td>
            <td>{`${loan.max_loan_amount} 
          ${translate('currency_unit', currentLanguage)}`}</td>
          </tr>
          <tr>
            <td>
              {` 📈 ${translate('interest_rate', currentLanguage)} `}
            </td>
            <td>{`  ${loan.interest_rate || 'n/a'} `}</td>
          </tr>
        </tbody>
      </table>

      <div className="aff-list__cta">
        <a href={`/lender${loan.slug}`} className="info-link">
          {translate('more_info', currentLanguage)}{' '}
        </a>
        <AffiliateLinkButton text={translate('cta_list_button', currentLanguage)} url={loan.affiliate_link} />
      </div>
    </li>
  );
}
