export const languages = [
  { name: 'English', code: 'en' },
  { name: 'Finnish', code: 'fi' },
  { name: 'Swedish', code: 'sv' },
];

const translate = (key, lang) => {
  if (!translations[lang]) return 'language key missing';
  if (!translations[lang][key]) return 'translation key missing';
  return translations[lang][key];
};

const translations = {
  en: {
    loan_amount_title: 'Loan amount',
    loan_period_title: 'Loan period',
    accepts_non_payment_record: 'Accepts non payment record',
    interest_free_loan: 'Räntefria lån',
    loan_without_credit_check: 'Snabblån Utan UC',
    weekend_payout: 'Helgutbetalningar',
    //sort options
    name_title: 'Name',
    rating: 'rating',
    total_cost: 'Total cost',
    interest_rate: 'Interest rate',
    effective_interest_rate: 'E. interest rate',
    cta_list_button: 'Continue',
    monthly_cost: 'Månadskostnad',
    featured_ribbon: 'Featured',
    year: 'years',
    month: 'months',
    currency_unit: 'EUR',
    loan_period: 'Loan Period',
    age_limit: 'Age limit',
    max_loan_amount: 'Max',
    min_loan_amount: 'Min',
    more_info: 'More info',
    show_only_business_loans: 'Show only business loans',
    button_visit: 'Visit'
  },
  sv: {
    loan_amount_title: 'Lånebelopp',
    loan_period_title: 'Lånebeperiod',
    accepts_non_payment_record: 'Accepterar betalningsanmärkningar',
    interest_free_loan: 'Erbjuder räntefria lån',
    loan_without_credit_check: 'Lån Utan UC',
    weekend_payout: 'Helgutbetalningar',
    //sort options
    name_title: 'Namn',
    rating: 'Betyg',
    total_cost: 'Total Kostnad',
    interest_rate: 'Ränta',
    effective_interest_rate: 'Eff. ränta',
    cta_list_button: 'Ansök',
    monthly_cost: 'Månadskostnad',
    featured_ribbon: 'Framhävd',
    year: 'år',
    month: 'mån',
    currency_unit: 'kr',
    loan_period: 'Låneperiod',
    age_limit: 'Åldersgräns',
    max_loan_amount: 'Max',
    min_loan_amount: 'Min',
    more_info: 'Mer info',
    show_only_business_loans: 'Visa endast företagslån',
    button_visit: 'Besök'
  },
  fi: {
    loan_amount_title: 'Laina määrä',
    loan_period_title: 'Laina aika',
    accepts_non_payment_record: 'Hyväskyy Maksuhäiriömerkinnät',
    interest_free_loan: 'Koroton ensilaina',
    loan_without_credit_check: 'Laina ilman Luottotietoja',
    weekend_payout: 'Auki viikonloppuisin',
    //sort options
    name_title: 'Nimi',
    rating: 'Luokitus',
    total_cost: 'Summa',
    interest_rate: 'Korko',
    effective_interest_rate: 'Tod. vuosikorko',
    cta_list_button: 'Hae Lainaa',
    monthly_cost: 'Kuukausimaksu',
    featured_ribbon: 'Suosittu',
    year: 'v',
    month: 'kk',
    currency_unit: '€',
    loan_period: 'Maksuaika',
    age_limit: 'Ikäraja',
    max_loan_amount: 'Max',
    min_loan_amount: 'Min',
    more_info: 'Lisää tietoa',
    show_only_business_loans: 'Näytä vain yrityslainat',
    button_visit: 'Katso Tarjoukset'
  },
};

export default translate;
