export const languages = [
  { name: 'English', code: 'en' },
  { name: 'Finnish', code: 'fi' },
  { name: 'Swedish', code: 'sv' },
];

const translate = (key, lang) => {
  if (!translations[lang]) return `Could not find language ${lang}:${key}`;
  if (!translations[lang][key]) return `Translation key missing ${lang}:${key}`;
  return translations[lang][key];
};

const translations = {
  en: {
    title_info_links: 'INFO LINKS',
    title_page_links: 'PAGES'
  },
  sv: {
    title_info_links: 'INFOLÄNKAR',
    title_page_links: 'SIDOR'
  },
  fi: {
    title_info_links: 'INFOLINKIT',
    title_page_links: 'SIVUT'
  },
};

export default translate;
