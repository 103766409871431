import React from 'react'
import Navigation from '../components/Navigation/Main'
import '../scss/style.scss'
import ScrollUpButton from 'react-scroll-up-button'
import Footer from '../components/Footer'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'

const Layout = ({ children }) => {
  return (
    <>
      <Navigation />
      <ScrollUpButton />
      <main>{children}</main>
      <Footer />
      <CookieConsent buttonText="Jag förstår" style={{ background: '#999' }}>
        Den här hemsidan använder kakor (cookies). Genom att surfa vidare på
        denna sida accepterar du våran cookie policy.&nbsp;
        <Link style={{ color: '#efefef' }} to="/cookie-policy/">
          Mer info
        </Link>
      </CookieConsent>
    </>
  )
}

export default Layout
