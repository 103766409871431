import React from 'react'
import './scss/index.scss'
import { FaFacebook, FaTwitter } from 'react-icons/fa'
import { StaticQuery, graphql, Link } from 'gatsby'
import translate from './i18n'

const defaultLanguage = 'sv'

export default () => (
  <StaticQuery
    query={graphql`
      query NavigationFooterMenuQuery {
        allNavigationYaml {
          nodes {
            name
            nav_links {
              label
              path
              is_title
            }
          }
        }
      }
    `}
    render={data => {
      const page_links = data.allNavigationYaml.nodes.find(
        node => node.name === 'footer_page_links'
      )
      const info_links = data.allNavigationYaml.nodes.find(
        node => node.name === 'footer_info_links'
      )
      return (
        <footer className="site-footer">
          <div className="footer_top">
            <div className="column column-23">
              <h4>DISCLAIMER & VILLKOR:</h4>
              <p>
                bluemonday.se ("vi") är inte en långivare, en finansiell
                rådgivare eller finansinstitut av något slag. Vi jämför
                finansiella produkter som lån och bygger onlineverktyg som
                hjälper våra användare att spara pengar och genom att välja
                fömånliga lån. Vi är inte ansvariga för felaktiga uppgifter
                eller felaktigheter i räntor, företagsinformation eller annan
                information, utan ansvaret ligger hos kunden, men givetvist
                strävar vi efter att alltid presentera så korrekt information vi
                bara kan på sidan. Kunden bör alltid kontrollera att
                informationen är korrekt innan denne använder en tjänst. När du
                använder bluemonday.se accepterar du vår policy när det gäller
                cookies och våra allmänna villkor. Vissa lån som kan hittas på
                vår sida kommer ifrån annonsörer och vi får ersättning för
                förmedlade låntagare.
              </p>
            </div>
            <div className="column column-13">
              <h3>{translate('title_page_links', defaultLanguage)}</h3>
              <ul className="footer-links">
                {page_links &&
                  page_links.nav_links.map((link, i) => (
                    <li key={i}>
                      <Link to={link.path}>{link.label}</Link>
                    </li>
                  ))}
              </ul>

              <h3>{translate('title_info_links', defaultLanguage)}</h3>
              <ul className="footer-links">
                {info_links &&
                  info_links.nav_links.map((link, i) => (
                    <li key={i}>
                      <Link to={link.path}>{link.label}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <hr />

          <div className="footer_bottom">
            <div className="column">
              <p className="copyright-text">
                Copyright &copy; {new Date().getFullYear()} All Rights Reserved
                by &nbsp;<Link to="/">Bluemonday.se</Link>
              </p>
            </div>

            <div className="column">
              <ul className="social-icons">
                <li>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/bluemondayfinanstips/"
                  >
                    <FaFacebook />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      )
    }}
  />
)
