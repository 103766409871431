import React from 'react'
import Slider from './Slider'
import './scss/main.scss'
import { StaticQuery, graphql, Link } from 'gatsby'

export default () => (
  <StaticQuery
    query={graphql`
      query NavigationMenuQuery {
        allNavigationYaml {
          nodes {
            name
            nav_links {
              label
              path
              is_title
            }
          }
        }
      }
    `}
    render={data => {
      const main_menu = data.allNavigationYaml.nodes.find(node => node.name === 'main')
      const slider_menu = data.allNavigationYaml.nodes.find(node => node.name === 'slider')
      return(<nav className="main-navigation">
        <ul className='nav-links'>
          <li className="logo-container">
            <Link className='top_link' to="/">
              <img
                className="logo"
                src="/images/raw-logo.png"
                alt="bluemonday logo"
              />
            </Link>
          </li>
          {main_menu.nav_links.map((link, i) => (
            <li className="desktop-link" key={i}>
              <Link className='top_link' to={link.path}>{link.label}</Link>
            </li>
          ))}
        </ul>
        <Slider links={slider_menu.nav_links} />
      </nav>)
    }}
  />
)
