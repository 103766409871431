import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'

function AffiliateLinkButton({ text, url }) {
  const handleAffiliateLinkClick = () => {
    window.open(url, '_blank')
  }

  return (
    <button
      className="cta-btn"
      onClick={() => handleAffiliateLinkClick()}
    >
      {text} &nbsp;
      <FaExternalLinkAlt style={{ marginBottom: 4 }}  size="12"/>
    </button>
  )
}

export default AffiliateLinkButton
