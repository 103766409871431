/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
// import { useStaticQuery, graphql } from 'gatsby'

function HeaderMeta({
  canonical,
  description,
  lang,
  meta,
  title,
  author = 'Bluemonday.se',
  image,
  siteName = 'bluemonday'
}) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `
  // )

  const metaDescription = description

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      link={
        canonical ? [{ rel: 'canonical', key: canonical, href: canonical }] : []
      }
      title={title}
      meta={[
        {
          name: 'og:image',
          content: image
        },
        {
          name: 'og:site_name',
          content: siteName
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    />
  )
}

HeaderMeta.defaultProps = {
  lang: `fi`,
  meta: [],
  description: ``
}

HeaderMeta.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  author: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default HeaderMeta
